import { Box } from '@awning/components/dist/Box';
import { Text } from '@awning/components/dist/Text';
import { Flex } from '@awning/components/dist/Flex';
import { ReactComponent as SVG404 } from '@/public/404.svg';

/* eslint-disable  @next/next/no-html-link-for-pages */
export default function Custom404({ blah }: any) {
  return (
    <Flex
      sx={{
        margin: '0 auto',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box as={SVG404} sx={{ mb: 4 }} />
      <Text
        sx={{
          text: '2xl',
          fontWeight: 'bold',
          my: 4,
        }}
      >
        {blah} We can't find this page.
      </Text>
      <Text>The page you're looking for doesn't seem to exist.</Text>
      <Box sx={{ mt: 5 }}>
        <a href="https://awning.com/a/top-airbnb-markets">Browse Top Airbnb Markets</a>
      </Box>
    </Flex>
  );
}
